//
// Copied from v3.1.3 of the `date-fns-tz` package.
// Source: https://github.com/marnusw/date-fns-tz/blob/v3.1.3/src/_lib/newDateUTC/index.ts
//

/**
 * Use instead of `new Date(Date.UTC(...))` to support years below 100 which doesn't work
 * otherwise due to the nature of the
 * [`Date` constructor](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#interpretation_of_two-digit_years.
 *
 * For `Date.UTC(...)`, use `newDateUTC(...).getTime()`.
 */
export function newDateUTC(
  fullYear: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number,
  millisecond: number
): Date {
  const utcDate = new Date(0)
  utcDate.setUTCFullYear(fullYear, month, day)
  utcDate.setUTCHours(hour, minute, second, millisecond)
  return utcDate
}